export const offers = [
	{
		id: 1,
		code: "FX",
		title: "Hayalinizdeki finansal başarıya giden yolda adım atın!",
		content:
			"Yatırım koçluğu ve finansal piyasalarla ilgili ücretsiz eğitime kaydolun, başarıya giden yolculuğunuzu başlatma fırsatı yakalayın.",
		imageSrc:
			"https://joy-leads.s3.eu-central-1.amazonaws.com/rs/fx.png",
		gender: 1,
		condition: {
			expression: "1:1",
		},
		page_name: "fx",
		aff_id: "0"
	},
	{
		id: 2,
		code: "Biwatt",
		title: "Türkiye'de ilk %100 yerli, %100 elektrikli araba kiralama servisi!",
		content:
			"Konforlu seyahat, sürdürülebilir ve güvenli ulaşımın adresi. Sürdürülebilir geleceğe Biwatt'la ışık yak. Araba kiralama seçiminle fark yarat!",
		imageSrc:
			"https://joy-leads.s3.eu-central-1.amazonaws.com/rs/biwatt.png",
		gender: 1,
		condition: {
			expression: "1:1",
		},
		page_name: "biwatt",
		aff_id: "101"
	}
	// },
	// {
	// 	id: 2,
	// 	code: "Bombsizeultra",
	// 	title: "Bomb Size Ultra ile mükemmel göğüsler",
	// 	content:
	// 		"Estetik ameliyat, ilaç veya başka bir ürüne ihtiyaç duymadan hayalinizdeki göğüslere kavuşmanız mümkün!",
	// 	imageSrc:
	// 		"https://joy-leads.s3.eu-central-1.amazonaws.com/rs/bombsizeultra1.png",
	// 	gender: 1,
	// 	condition: {
	// 		expression: "1:1",
	// 	},
	// 	page_name: "bombsizeultra",
	// 	aff_id: "34610",
	// },
	// {
	// 	id: 3,
	// 	code: "Ketoburn",
	// 	title: "Fazla yağlardan kurtulmak artık hayal değil!",
	// 	gender: 1,
	// 	content:
	// 		"Hem hipertansiyonun hem de bağışıklık sistemi zayıflığının temel sebebi yağlardır. Daha sağlıklı yarınlar için bugünden adım atın.",
	// 	imageSrc:
	// 		"https://joy-leads.s3.eu-central-1.amazonaws.com/rs/ketoburn1.png",
	// 	page_name: "ketoburn",
	// 	aff_id: "34610",
	// },
	// {
	// 	id: 4,
	// 	code: "Diabacore",
	// 	title: "DiabaCore ile diyabete bitkisel çözüm",
	// 	gender: 1,
	// 	content:
	// 		"Sağlığınızı korumak ve diyabet hastalığının etkilerini en az seviyede hissetmek için 100% doğal içerikli bitkisel tabletler",
	// 	imageSrc:
	// 		"https://joy-leads.s3.eu-central-1.amazonaws.com/rs/diabacore1.png",
	// 	page_name: "diabacore",
	// 	aff_id: "34610",
	// },
	// {
	// 	id: 5,
	// 	code: "HealthyHeart",
	// 	title: "Healthy Heart ile yüksek kan basıncına elveda",
	// 	gender: 1,
	// 	content:
	// 		"İlk kullanımdan itibaren fark edilir sonuçlar: Hastalığın sebebini hedef alarak felç ve damar tıkanıklığı riskini sıfıra indirir!",
	// 	imageSrc:
	// 		"https://joy-leads.s3.eu-central-1.amazonaws.com/rs/healthyheart1.png",
	// 	page_name: "healtyheart",
	// 	aff_id: "34610",
	// },
	// {
	// 	id: 6,
	// 	code: "Collagen",
	// 	gender: 1,
	// 	title: "Kollagen Intensiv ile kırışıklıklara son!",
	// 	content:
	// 		"Kırışıklık ve sarkma başta olmak üzere bütün cilt sorunlarınızın cevabı burada - Doğal ve bitkisel formülle kolay ve hızlı çözüm!",
	// 	imageSrc:
	// 		"https://joy-leads.s3.eu-central-1.amazonaws.com/rs/collagen1.png",
	// 	page_name: "kollagen",
	// 	aff_id: "34610",
	// },
	/* {
		id: 7,
		code: "JointFlex",
		title: "Artık eklemleriniz gençliğinizdeki gibi olabilir!",
		gender: 1,
		content:
			"Mucizeler her yaşta gerçekleşebilir! Eklem tedavisiyle gençliğinizdeki hareket kabiliyetinize yeniden kavuşun.",
		imageSrc:
			"https://joy-leads.s3.eu-central-1.amazonaws.com/rs/jointflex1.png",
	}, */
];
