import React, { useState, useEffect, createRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "survey-core/modern.min.css";
import * as Survey from "survey-react";
//Json for form Survey
import "./SurveyJS/Index";
import { FlowContext } from '../../../Providers/FlowProvider';

Survey.StylesManager.applyTheme("modern");
export const SurveyPagesData = [
	{
		name: "1. Soru",
		navigationTitle: "1",
		elements: [
			{
				type: "panel",
				name: "panel",
				elements: [
					{
						type: "metric",
						name: "metric",
						title: "Text",
						titleLocation: "hidden",
					},
				],
			},
			{
				type: "image",
				name: "first_page_image",
				imageLink:
					"https://joy-leads.s3.eu-central-1.amazonaws.com/rs/q1.png",
			},
			{
				type: "radiogroup",
				inputType: "radiogroup",
				name: "radiogroupquestions1",
				title: `Önce cinsiyetini öğrenelim:`,
				hasNone: true,
				choices: ["Kadın", "Erkek", "Diğer / Belirtmek istemiyorum"],
				titleLocation: "hidden",
			},
		],
	},
	{
		name: "2. Soru",
		navigationTitle: "2",
		/* 	navigationDescription: "overload - MUI", */
		elements: [
			{
				type: "image",
				name: "second_page_image",
				imageLink:
					"https://joy-leads.s3.eu-central-1.amazonaws.com/rs/q2.png",
			},
			{
				type: "radiogroup",
				inputType: "radiogroup",
				name: "radiogroupquestions2",
				title: `Şimdi de yaşını söyle bakalım:`,
				hasNone: true,
				choices: [
					"20 yaş altı",
					"20-29",
					"30-39",
					"40-49",
					"50-65",
					"65 yaş üstü",
				],
				titleLocation: "hidden",
			},
		],
	},
	{
		name: "3. Soru",
		navigationTitle: "3",
		elements: [
			{
				type: "image",
				name: "third_page_image",
				imageLink:
					"https://joy-leads.s3.eu-central-1.amazonaws.com/rs/q3.png",
			},
			{
				type: "radiogroup",
				inputType: "radiogroup",
				name: "radiogroupquestions3",
				title: `Son olarak, yaşadığın bölgeyi de öğrenelim:`,
				hasNone: true,
				choices: [
					"Marmara",
					"Karadeniz",
					"Doğu Anadolu",
					"Güneydoğu Anadolu",
					"Akdeniz",
					"Ege",
					"İç Anadolu",
				],
				titleLocation: "hidden",
			},
		],
	},
	{
		name: "4. Soru",
		navigationTitle: "4",
		elements: [
			{
				type: "image",
				name: "fourth_page_image",
				imageLink:
					"https://joy-leads.s3.eu-central-1.amazonaws.com/rs/q4.png",
			},
			{
				type: "radiogroup",
				inputType: "radiogroup",
				name: "radiogroupquestions4",
				title: `Dilini bilmediğin bir ülkeye tatile gittin diyelim. Bir restorana girdin ve güçlükle sipariş verdin ancak önüne koydukları yemeğin ne olduğuna dair en ufak bir fikrin dahi yok. Ne yaparsın?`,
				hasNone: true,
				choices: [
					"Ne restoranı? Memleketten peynir ekmek getirdim ben, bilmediğim şeyi yemem!",
					"Yani buralara kadar gelmişiz, en azından bir tadına bakarım.",
					"Şuradan tuzluğu uzatsana bana!",
				],
				titleLocation: "hidden",
			},
		],
	},
	{
		name: "5. Soru",
		navigationTitle: "5",
		elements: [
			{
				type: "image",
				name: "fifth_page_image",
				imageLink:
					"https://joy-leads.s3.eu-central-1.amazonaws.com/rs/q5.png",
			},
			{
				type: "radiogroup",
				inputType: "radiogroup",
				name: "radiogroupquestions5",
				title: `Tatil demişken, seyahat ederken hangi aracı tercih edersin?`,
				hasNone: true,
				choices: [
					"Tren",
					"Uçak",
					"Otobüs",
				],
				titleLocation: "hidden",
			},
		],
	},
	{
		name: "6. Soru",
		navigationTitle: "6",
		elements: [
			{
				type: "image",
				name: "sixth_page_image",
				imageLink:
					"https://joy-leads.s3.eu-central-1.amazonaws.com/rs/q6.png",
			},
			{
				type: "radiogroup",
				inputType: "radiogroup",
				name: "radiogroupquestions6",
				title: `Bir de hangi özel aracı kullanmayı tercih ettiğini alalım:`,
				hasNone: true,
				choices: [
					"Araba",
					"Bisiklet",
					"Motosiklet",
				],
				titleLocation: "hidden",
			},
		],
	},
	{
		name: "7. Soru",
		navigationTitle: "7",
		elements: [
			{
				type: "image",
				name: "seventh_page_image",
				imageLink:
					"https://joy-leads.s3.eu-central-1.amazonaws.com/rs/q7.png",
			},
			{
				type: "radiogroup",
				inputType: "radiogroup",
				name: "radiogroupquestions7",
				title: `Arabayla şehirler arası yolda ilerliyorsun diyelim. Hız nedeniyle radara yakalanma ihtimalin nedir?`,
				hasNone: true,
				choices: [
					"Arkadaki traktör selektör mü yapıyor bana?",
					"Yol boşsa azıcık çılgınlık yapabilirim ama abartmam elbette.",
					"Gaz pedalını öyle bir köklerim ki radar bile beni yakalayamaz.",
				],
				titleLocation: "hidden",
			},
		],
	},
	{
		name: "8. Soru",
		navigationTitle: "8",
		elements: [
			{
				type: "image",
				name: "eighth_page_image",
				imageLink:
					"https://joy-leads.s3.eu-central-1.amazonaws.com/rs/q8.png",
			},
			{
				type: "radiogroup",
				inputType: "radiogroup",
				name: "radiogroupquestions8",
				title: `Arabanın bakımını düzenli olarak yapıyor musun peki?`,
				hasNone: true,
				choices: [
					"Evladıma göstermediğim şefkati arabama gösteririm, bakım da neymiş.",
					"Çok abartmam ama en azından haftada bir yağını kontrol ederim.",
					"Ehliyetim yokki...",
				],
				titleLocation: "hidden",
			},
		],
	},
	{
		name: "9. Soru",
		navigationTitle: "9",
		elements: [
			{
				type: "image",
				name: "ninth_page_image",
				imageLink:
					"https://joy-leads.s3.eu-central-1.amazonaws.com/rs/q9.png",
			},
			{
				type: "radiogroup",
				inputType: "radiogroup",
				name: "radiogroupquestions9",
				title: `Kredi kartlarıyla aran nasıl bir de onu anlat bakalım.`,
				hasNone: true,
				choices: [
					"Düzenli kullanırım ama sınırımı aşmam, her ay borcumun tamamını öderim..",
					"Tüm borcu kapatamasam da en azından asgari borcu mutlaka öderim.",
					"Kredi kartı kullanmıyorum.",
				],
				titleLocation: "hidden",
			},
		],
	},
	{
		name: "10. Soru",
		navigationTitle: "10",
		elements: [
			{
				type: "image",
				name: "tenth_page_image",
				imageLink:
					"https://joy-leads.s3.eu-central-1.amazonaws.com/rs/q10.png",
			},
			{
				type: "radiogroup",
				inputType: "radiogroup",
				name: "radiogroupquestions10",
				title: `Birikimini nasıl değerlendirdiğini de bir anlat bakalım:`,
				hasNone: true,
				choices: [
					"Vadeli hesaptan şaşmam. Faiz oranları da yükseldi, benden güzeli yok!",
					"Altın, döviz, yatırım fonu falan ortaya karışık takılıyorum işte.",
					"Kefen param dahil tüm birikimimi bitcoin’e yatırdım, yine olsa yine yaparım.",
				],
				titleLocation: "hidden",
			},
		],
	},
	{
		name: "11. Soru",
		navigationTitle: "11",
		elements: [
			{
				type: "image",
				name: "tenth_page_image",
				imageLink:
					"https://joy-leads.s3.eu-central-1.amazonaws.com/rs/q11.png",
			},
			{
				type: "radiogroup",
				inputType: "radiogroup",
				name: "radiogroupquestions11",
				title: `Bir kenarda biriktirmekten daha büyük bir yatırım yapmak istiyorsun diyelim. Uzun vadeli finansal planların için hangisini tercih edersin?`,
				hasNone: true,
				choices: [
					"Başımı sokacak bir damım olsun da isterse o dam aksın, fark etmez. Gayrimenkulden şaşmam ben.",
					"Heyecan severim ama bir yere kadar; o yüzden altın ve her türlü mevduat yatırımı bana göre.",
					"Ben hiçbir şeye uzun süre bağlanamam, hız benim için her şey. Forex olur, borsa olur… Likit yatırım tam bana göre!",
				],
				titleLocation: "hidden",
			},
		],
	},
	{
		name: "12. Soru",
		navigationTitle: "12",
		elements: [
			{
				type: "image",
				name: "tenth_page_image",
				imageLink:
					"https://joy-leads.s3.eu-central-1.amazonaws.com/rs/q12.png",
			},
			{
				type: "radiogroup",
				inputType: "radiogroup",
				name: "radiogroupquestions12",
				title: `Birazcık da genel sağlığından bahsedelim: Beslenmene dikkat eder misin?`,
				hasNone: true,
				choices: [
					"Üç beyazı hayatımdan çıkardım, Canan Karatay görse alnımdan öper beni",
					"Bazen biraz fazla kaçırdığım oluyor genel olarak dikkatliyimdir.",
					"Gençliğinde yemeyen yaşlılığında kaçırdıklarına ağlar, devamke!",
				],
				titleLocation: "hidden",
			},
		],
	},
	{
		name: "13. Soru",
		navigationTitle: "13",
		elements: [
			{
				type: "image",
				name: "tenth_page_image",
				imageLink:
					"https://joy-leads.s3.eu-central-1.amazonaws.com/rs/q13.png",
			},
			{
				type: "radiogroup",
				inputType: "radiogroup",
				name: "radiogroupquestions13",
				title: `Diş bakımına özen gösterir misin?`,
				hasNone: true,
				choices: [
					"6 ayda bir rutin diş kontrollerimi yaptırırım, her yemekten sonra mutlaka dişlerimi fırçalarım.",
					"Bazen aksattığım oluyor ama genel olarak dikkat etmeye çalışırım.",
					"Manitayla buluşmadan buluşmaya dişlerimi fırçalarım.",
				],
				titleLocation: "hidden",
			},
		],
	},
	{
		name: "14. Soru",
		navigationTitle: "14",
		elements: [
			{
				type: "image",
				name: "tenth_page_image",
				imageLink:
					"https://joy-leads.s3.eu-central-1.amazonaws.com/rs/q14.png",
			},
			{
				type: "radiogroup",
				inputType: "radiogroup",
				name: "radiogroupquestions14",
				title: `Hiç check-up yaptırdın mı?`,
				hasNone: true,
				choices: [
					"Her yıl mutlaka check-up yaptırırım; sağlık şakaya gelmez!",
					"Düzenli yaptırmasam da fırsat bulduğumda genel kontrolden geçerim.",
					"Bir rahatsızlık hissedersem doktora giderim ama hiç check-up yaptırmadım.",
				],
				titleLocation: "hidden",
			},
		],
	}
]
export default function SurveyJS() {
	const { flowIndex } = useContext(FlowContext);

	const surveyRef = createRef();
	const navigate = useNavigate();
	const [name, setName] = useState("");
	let choiceCounts = {
		1: 0,
		2: 0,
		3: 0,
	};

	const surveyModel = new Survey.Model({
		pages: SurveyPagesData,
		showProgressBar: "top",
		showNavigationButtons: "none",
		goNextPageAutomatic: true,
		allowCompleteSurveyAutomatic: true,
		progressBarType: "buttons",
	});

	const makeNextButtonClick = () => {
		var element = document.getElementsByClassName(
			"sv_progress-buttons__image-button-right"
		)[0]; //grab the element
		element.click();
	};

	const storeChoices = (question) => {
		question.question.choices.map((choice, index) => {
			if (choice.selected) {
				const temp = {
					...choiceCounts,
				};

				if (index < 3) {
					temp[index + 1] = temp[index + 1] + 1;

					choiceCounts = {
						...temp,
					};
				}
			}
		});
	};

	const setChoices = () => {
		const choiceCountsString = JSON.stringify(choiceCounts);

		localStorage.setItem("choiceCounts", choiceCountsString);
	};

	const generateNavigateCompleteSurvey = (gender) => {
		if (flowIndex === 0) {
			navigate(`/offers/${gender}?fIndex=${flowIndex}`, { replace: true });
		}
		else {
			navigate('/final' + '?fIndex=' + flowIndex);
		}
	}

	const completeSurvey = (data) => {
		const results = JSON.stringify(data);
		localStorage.setItem("answers", results);
		localStorage.setItem("gender", data === "Kadın" ? 0 : 1);

		const gender = data === "Kadın" ? 0 : 1;
		generateNavigateCompleteSurvey(gender);
	};


	const removeNameInput = () => {
		let toRemoveEl = document?.getElementsByClassName('nameInput')[0];
		let toRemoveEl2 = document?.getElementsByClassName('nameInput')[1];
		const parentElement = toRemoveEl?.parentNode;
		parentElement?.removeChild(toRemoveEl);
		parentElement?.removeChild(toRemoveEl2);
	}

	surveyModel.focusFirstQuestionAutomatic = true;


	surveyModel.onValueChanging.add((survey, question) => {
		const currentQuestion = survey.currentPageNo + 1;
		if (currentQuestion >= 1) {
			removeNameInput();
		}
		if (currentQuestion > 2) {
			storeChoices(question);
		}

		if (currentQuestion === 14) {
			setTimeout(() => {
				setChoices();
				surveyModel.doComplete();
			}, 200);
		}

		ls('event', 'question', '{"question": "' + currentQuestion + '"}');

		// ls('event','form_submission','{"form_type":"İletişim","user_data:"[{"user_email":"test@gmail.com","user_phone":"+905555555555"}]}');
		// ls('event','cta_button_click','{"click_text":"Hemen Teklif Al"}');
		// ls('event','offer_click','{"offer_type":"Kasko Sigortası"}');
		// ls('event','vehicle_status','{"insurance_type":"Aracımın Sigortası Bitiyor", "offer_type":"Kasko Sigortası"}');
		// ls('event','insurance_information','{"business_type":"Şahıs","offer_type":"Kasko Sigortası", "user_data:"[{"user_email":"test@gmail.com","user_phone":"+905555555555"}]}');
		// ls('event','vehicle_renewal','{"business_type":"Şahıs","offer_type":"Kasko Sigortası"}');
		// ls('event','get_offer','{"business_type":"Şahıs","offer_type":"Kasko Sigortası"}');
		// ls('event','offer_success','{"page_type":"offer","business_type":"Şahıs","offer_type":"Kasko Sigortası"}');
		// ls('event','offer_success','{"page_type":"sms","business_type":"Şahıs","offer_type":"Kasko Sigortası"}');
		// ls('event','view_item_list','{"item_list_name":"teklif", "ecommerce": {"items": [{"item_id": "12345","item_name": "Ekonomik Kasko", "affiliation": "AKSigorta","currency": "TRY","discount": "0","index": "0","item_brand": "AKSigorta","price": "37420.67","quantity": "1"}, {"item_id": "223344","item_name": "Genişletilmiş Kasko", "affiliation": "AKSigorta","currency": "TRY","discount": "0","index": "1","item_brand": "AKSigorta","price": "46069.91","quantity": "1"}]}}');
		// ls('event','view_item','{"value": "37420.67","currency": "TRY", "ecommerce": {"items": [{"item_id": "12345","item_name": "Ekonomik Kasko", "affiliation": "AKSigorta","currency": "TRY","discount": "0","index": "0","item_brand": "AKSigorta","price": "37420.67","quantity": "1"}]}}');
		// ls('event','begin_checkout','{"value": "37420.67","currency": "TRY", "ecommerce": {"items": [{"item_id": "12345","item_name": "Ekonomik Kasko", "affiliation": "AKSigorta","currency": "TRY","discount": "0","index": "0","item_brand": "AKSigorta","price": "37420.67","quantity": "1"}]}}');
		// ls('event','add_payment_info','{"value": "48346.02","currency": "TRY", "coupon": "YAZ", "payment_type": "Taksit", "ecommerce": {"items": [{"item_id": "12345","item_name": "Ekonomik Kasko", "affiliation": "AKSigorta","currency": "TRY","discount": "0","index": "0","item_brand": "AKSigorta","price": "37420.67","quantity": "1"}]}}');
		// ls('event','purchase','{"ecommerce": {"transaction_id":"T_12345", "affiliation":"AKSigorta", "value": "48346.02", "tax":"4000", "shipping":"0",  "currency": "TRY", "payment_type": "Taksit", "coupon": "YAZ", "items": [{"item_id": "12345","item_name": "Ekonomik Kasko", "affiliation": "AKSigorta","currency": "TRY","discount": "0","index": "0","item_brand": "AKSigorta","price": "37420.67","quantity": "1"}]}}');
		// ls('event',phone_policy,'{"insurance_type":"Aracımın Sigortası Bitiyor", "offer_type":"Kasko Sigortası"}');


		makeNextButtonClick();

		setTimeout(() => {
			surveyModel.nextPage();

			setTimeout(() => {
				window.scrollTo({
					left: 0,
					top: document.body.scrollHeight,
					behavior: "smooth",
				});
			}, 250);
		}, 300);
	});

	surveyModel.onComplete.add((sender) => {
		completeSurvey(sender.data);
	});

	surveyModel.completedHtml = "<div></div>";


	useEffect(() => {
		const storedName = localStorage.getItem('name');
		if (storedName) {
			setName(storedName);
		}

		let el = document.getElementById('sp_100')
		if (el && surveyRef.current.survey.currentPageNo === 0 && flowIndex !== 0) {
			const labelElement = document.createElement('label');
			labelElement.htmlFor = 'inputElement';
			labelElement.textContent = 'Adınız Soyadınız';
			labelElement.className = "nameInput nameInputLabel";
			const inputElement = document.createElement('input');
			inputElement.type = 'text';
			inputElement.placeholder = 'Lütfen adınızı soyadınızı giriniz';
			inputElement.className = "nameInput";
			inputElement.maxLength = 30;
			inputElement.value = storedName ? storedName : '';
			inputElement.oninput = (event) => {
				setName(event.target.value)
				localStorage.setItem("name", event?.target?.value);
			};
			const firstChild = el.firstChild;
			el.insertBefore(labelElement, firstChild);
			el.insertBefore(inputElement, firstChild);
		}
	}, [surveyRef])


	// useEffect(() => {
	// 	let titleEl = document.getElementsByClassName('title')[0];
	// 	if (titleEl) {
	// 		titleEl.textContent = name + (name?.length > 0 ? ", " : "") + titleEl.textContent;
	// 	}
	// }, [name])
	return (
		<div id="form_survey">
			<Survey.Survey ref={surveyRef} model={surveyModel} />
		</div>
	);
}
